/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

import {
  Button,
  Divider,
  Grid,
  Layout,
  Form,
  Modal,
  Card,
  Dialog as DialogComponent,
} from '@enterprise-ui/canvas-ui-react'
import { UpdateCase, AcceptReview } from '../../service/VisitExternalApiCalls'
import BotResponseBubble from './BotResponseBubble'
import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'
import { FileUploadComponent } from './FileUploadComponent'

const getStyles = () => ({
  textBubbleCls: {
    backgroundColor: '#EAEAEA',
    fontSize: '1.0rem',
    lineHeight: '1.3rem',
    padding: '12px',
  },
  userActionBtnCls: {
    textAlign: 'center',
  },
  marginRightBtn: {
    marginRight: '20px',
  },
})

export const FinalReviewPage = () => {
  const { reviewResponse, caseAttachements } =
    React.useContext(VisitUUIDContext)
  const [reviewData, setReviewData] = React.useState(reviewResponse)
  const styles = getStyles()
  const [visitObj, setVisitObj] = React.useState([])
  const [generalComments, setGeneralComments] = React.useState([])
  const [caseAttachmentsList, setCaseAttachmentsList] = React.useState([])
  const [attachements, setAttachements] = React.useState([])
  const [comments, setcomments] = React.useState()
  const [commentBoxHeading, setCommentBoxHeading] =
    React.useState('Add comment')
  const [isCommentBoxOpen, setIsCommentBoxOpen] = React.useState(false)
  const [isDialogVisible, setIsDialogVisible] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [caseStatus, setCaseStatus] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [resolveRejectPressed, setResolveRejectPressed] = React.useState(false)

  React.useEffect(() => {
    setReviewData(reviewResponse)
    setCaseAttachmentsList(caseAttachements)
  }, [reviewResponse, caseAttachements])

  const handleChangeComment = (event) => {
    setcomments(event.target.value)
  }

  const updateAttachments = (res) => {
    setAttachements(res)
  }

  React.useEffect(() => {
    let arr = []
    let data = {}
    let obj = {}

    setUserId(reviewData['user_id'])
    if (reviewData['location_id']) {
      obj = {}
      obj.label = 'Location ID'
      obj.value = reviewData['location_id']
      arr.push(obj)
    }
    if (reviewData['vendor_id']) {
      obj = {}
      obj.label = 'Vendor ID'
      obj.value = reviewData['vendor_id']
      arr.push(obj)
    }
    if (reviewData['vendor_name']) {
      obj = {}
      obj.label = 'Vendor name'
      obj.value = reviewData['vendor_name']
      arr.push(obj)
    }

    if (reviewData['status']) {
      setCaseStatus(reviewData['status'])
      obj = {}
      obj.label = 'Status'
      obj.value = reviewData['status']
      arr.push(obj)
    }
    if (reviewData['reason']) {
      obj = {}
      obj.label = 'Reason'
      obj.value = reviewData['reason']
      arr.push(obj)
    }
    if (reviewData['case_type']) {
      obj = {}
      obj.label = 'Case Type'
      obj.value = reviewData['case_type']
      arr.push(obj)
    }
    if (reviewData['issue_reason']) {
      obj = {}
      obj.label = 'Issue reason'
      obj.value = reviewData['issue_reason']
      arr.push(obj)
    }
    if (reviewData['issue_type']) {
      obj = {}
      obj.label = 'Issue type'
      obj.value = reviewData['issue_type']
      arr.push(obj)
    }
    if (reviewData['system_name']) {
      obj = {}
      obj.label = 'System name'
      obj.value = reviewData['system_name']
      arr.push(obj)
    }
    if (reviewData['request_description']) {
      obj = {}
      obj.label = 'Item description'
      obj.value = reviewData['request_description']
      arr.push(obj)
    }

    if (reviewData['created_by']) {
      obj = {}
      obj.label = 'Created by'
      obj.value = reviewData['created_by']
      arr.push(obj)
    }
    if (reviewData['updated_by']) {
      obj = {}
      obj.label = 'Updated by'
      obj.value = reviewData['updated_by']
      arr.push(obj)
    }
    data.visitArr = arr

    setVisitObj(data)
    setGeneralComments(reviewData?.comments)
  }, [reviewData])

  const handleStatus = (btnValue) => {
    let data = {}
    let message = ''
    data.user_id = userId
    data.updated_by = userId

    if (btnValue.toLowerCase() === 'accept') {
      data.status = 'In Progress'
      message = 'Case successfully accepted'
      AcceptReview(reviewData['case_id'], reviewData['uuid'], data).then(
        (response) => {
          setCaseStatus(response.data.status)
          setAlertMessage(message)
          setIsDialogVisible(true)
          setReviewData({ ...reviewData, status: response.data.status })
        },
      )
    } else if (btnValue.toLowerCase() === 'resolved') {
      message = 'Case successfully resolved'
      setCommentBoxHeading('Add message/reason for resolve')
    } else if (btnValue.toLowerCase() === 'rejected') {
      message = 'Case successfully rejected'
      setCommentBoxHeading('Add message/reason for reject')
    }
    setAlertMessage(message)

    if (
      btnValue.toLowerCase() === 'resolved' ||
      btnValue.toLowerCase() === 'rejected'
    ) {
      setResolveRejectPressed(true)
      setIsCommentBoxOpen(true)
    }
  }

  const handleComment = (event) => {
    setIsCommentBoxOpen(false)
    event.preventDefault()
    if (comments === undefined || comments === '') {
      setResolveRejectPressed(false)
    } else if (resolveRejectPressed === true) {
      let data = {}
      data.user_id = userId
      data.updated_by = userId
      data.comments = comments

      if (alertMessage.indexOf('resolved') > 0) {
        data.status = 'Resolved'
      } else if (alertMessage.indexOf('rejected') > 0) {
        data.status = 'Rejected'
      }
      AcceptReview(reviewData['case_id'], reviewData['uuid'], data).then(
        (response) => {
          setCaseStatus(response.data.status)
          setGeneralComments(response.data.comments)
          setIsDialogVisible(true)
          setResolveRejectPressed(false)
          setReviewData({ ...reviewData, status: response.data.status })
          setcomments('')
        },
      )
    } else {
      UpdateCase(reviewData['case_id'], reviewData['uuid'], {
        user_id: userId,
        updated_by: userId,
        comments: comments,
      }).then((response) => {
        setGeneralComments(response.data.comments)
        setcomments('')
      })
    }
  }

  const handleCancel = () => {
    setResolveRejectPressed(false)
    setIsCommentBoxOpen(false)
  }

  const commentButtonClicked = () => {
    setIsCommentBoxOpen(true)
    setCommentBoxHeading('Add comment')
  }

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="flex-start"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          {reviewData && Object.keys(reviewData).length > 0 && (
            <Grid.Item xs={12}>
              <p style={{ padding: '1px', fontSize: '20px' }}>
                DSD case details - {reviewData.vendor_name} -{' '}
                {reviewData.location_id}
              </p>
              <Divider width="2" direction="horizontal" wide={5} />
              <br />
              <Grid.Container direction="row" justify="space-between">
                <Grid.Item className="hc-pt-dense" xs={12}>
                  {visitObj && visitObj?.visitArr?.length > 0 && (
                    <BotResponseBubble
                      data={visitObj}
                      generalComments={generalComments}
                      attachements={attachements}
                      caseAttachmentsList={caseAttachmentsList}
                    />
                  )}
                </Grid.Item>
                {(caseStatus.toLowerCase() === 'assigned' ||
                  caseStatus.toLowerCase() === 'in progress') && (
                  <>
                    <Grid.Container spacing="dense" className="hc-ma-md">
                      <Grid.Item xs={12} md={6}>
                        <Card className="hc-pa-normal" align="center">
                          <FileUploadComponent
                            data={reviewData}
                            attchedResponse={updateAttachments}
                          />
                        </Card>
                      </Grid.Item>
                      <Grid.Item xs={12} md={6}>
                        <Card className="hc-pa-normal" align="center">
                          <Button
                            type="primary"
                            onClick={commentButtonClicked}
                            size="expanded"
                            className="hc-pa-expanded hc-ma-md"
                          >
                            Add comment
                          </Button>
                        </Card>
                      </Grid.Item>
                    </Grid.Container>
                  </>
                )}

                <Grid.Item xs={12}>
                  <Modal
                    isVisible={isCommentBoxOpen}
                    headingText={commentBoxHeading}
                    onRefuse={() => setIsCommentBoxOpen(false)}
                  >
                    <div className="hc-pa-normal">
                      <Grid.Container>
                        <Grid.Item xs={12}>
                          <Form.Field
                            id="filled-multiline-static"
                            type="text"
                            placeholder="Add your comment here..."
                            variant="filled"
                            value={comments}
                            classname="hc-pa-3x"
                            onChange={handleChangeComment}
                          />
                        </Grid.Item>
                      </Grid.Container>

                      <Grid.Container direction="row-reverse" spacing="dense">
                        <Grid.Item>
                          <Button onClick={handleComment} type="primary">
                            Save
                          </Button>
                        </Grid.Item>
                        <Grid.Item>
                          <Button onClick={handleCancel} type="secondary">
                            Cancel
                          </Button>
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Modal>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          )}
        </Grid.Container>
      </Layout.Body>
      <DialogComponent
        headingText={alertMessage}
        isVisible={isDialogVisible}
        approveButtonText={'Ok'}
        onApprove={() => {
          setIsDialogVisible(false)
        }}
      />
      <Layout.Footer includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item xs={12} style={styles.userActionBtnCls}>
            {caseStatus.toLowerCase() === 'assigned' && (
              <Button
                style={styles.marginRightBtn}
                type="primary"
                size="expanded"
                className="hc-pa-expanded"
                onClick={() => handleStatus('accept')}
              >
                Accept
              </Button>
            )}

            {caseStatus.toLowerCase() === 'in progress' && (
              <Button
                style={styles.marginRightBtn}
                type="primary"
                size="expanded"
                className="hc-pa-expanded"
                onClick={() => handleStatus('resolved')}
              >
                Resolve
              </Button>
            )}

            {(caseStatus.toLowerCase() === 'assigned' ||
              caseStatus.toLowerCase() === 'in progress') && (
              <>
                <Button
                  type="secondary"
                  size="expanded"
                  className="hc-pa-expanded"
                  onClick={() => handleStatus('rejected')}
                >
                  Reject
                </Button>
              </>
            )}

            {caseStatus.toLowerCase() === 'resolved' && (
              <Button
                type="secondary"
                size="expanded"
                className="hc-pa-expanded"
                disabled
              >
                Resolved
              </Button>
            )}

            {caseStatus.toLowerCase() === 'rejected' && (
              <Button
                type="secondary"
                size="expanded"
                className="hc-pa-expanded"
                disabled
              >
                Rejected
              </Button>
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
