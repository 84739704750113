import { Form } from '@enterprise-ui/canvas-ui-react'
import { ChildRenderFunction } from './ActionUtils'

function ParentandChildSelectCompnent({
  customMetadata,
  visit,
  visitCustomData,
  attributeName,
  onChangeHandler,
  domainLookUps,
  entry,
  handleChangeMultiSelect,
  setattachemntlist,
  attachmentlist,
  field,
}) {
  function findValueByKey(obj, targetKey) {
    let foundValue = undefined

    function searchObject(currentObj) {
      for (const key in currentObj) {
        if (typeof currentObj[key] === 'object') {
          searchObject(currentObj[key])
        } else if (key === targetKey) {
          foundValue = currentObj[key]
          return // Exit loop early if key is found
        }
      }
    }
    searchObject(obj)
    return foundValue
  }
  const renderOptions = (domainvalue) => {
    let options = []
    // eslint-disable-next-line array-callback-return
    domainLookUps?.filter((option) => {
      if (option?.domain_type === domainvalue) {
        options?.push({
          label: option?.domain_value,
          value: option?.domain_value,
        })
      }
    })
    return options
  }

  const parentSelectedValueforChild = findValueByKey(visit, attributeName)
  const findtheDomainId = domainLookUps.find(
    (domain) => domain?.domain_value === parentSelectedValueforChild,
  )?.domain_id
  const options = renderOptions(customMetadata[entry]?.domain_type)
  return (
    <>
      <Form.Field
        classname="hc-pa-3x"
        id={customMetadata[entry]?.label}
        label={customMetadata[entry]?.label}
        required={customMetadata[entry]?.required}
        options={options}
        value={
          customMetadata[entry]?.core_field
            ? visit[attributeName]
            : visitCustomData !== null
              ? visitCustomData[customMetadata[entry]?.label]
              : null
        }
        disabled={
          customMetadata[entry]?.domain_type !== null &&
          (customMetadata[entry]?.domain_type === 'DISTRIBUTOR' ||
            customMetadata[entry]?.domain_type === 'MANUFACTURE')
            ? true
            : false
        }
        type="select"
        size="expanded"
        onUpdate={(id, value) => {
          onChangeHandler(field, customMetadata[entry], value)
        }}
      />

      {customMetadata[entry]?.children_details?.length > 0 &&
      (findtheDomainId !== undefined || null || '') ? (
        <ChildRenderFunction
          parentCounterDomainId={findtheDomainId}
          childrenFieldDetails={customMetadata[entry]?.children_details}
          customMetadata={customMetadata}
          handleChangeMultiSelect={handleChangeMultiSelect}
          visit={visit}
          visitCustomData={visitCustomData}
          attributeName={attributeName}
          domainLookUps={domainLookUps}
          entry={entry}
          handleChangeCheckin={onChangeHandler}
          setattachemntlist={setattachemntlist}
          attachmentlist={attachmentlist}
          field={field}
        />
      ) : (
        <></>
      )}
      {/* </Grid> */}
      {/* </Grid> */}
    </>
  )
}

export default ParentandChildSelectCompnent
