/* eslint-disable array-callback-return */
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { VisitUUIDContext } from '../../../contexts/VisitUUIDContext'
import {
  Button,
  Divider,
  Grid,
  Input,
  Layout,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { UpdateVisit } from '../../../service/VisitExternalApiCalls'
import RenderFields from '../../Utils/RenderFields'
import EnterpriseIcon, { ChevronRightIcon } from '@enterprise-ui/icons'
import { ResponseContext } from '../../../contexts/ResponseContext'

export const CheckoutIndex = () => {
  const {
    systemName,
    customMetaData,
    visitorTypeDetails,
    locationId,
    visitUUID,
    domainLookUp,
    visitID,
  } = React.useContext(VisitUUIDContext)

  const [autocompleteValue, setAutocompleteValue] = React.useState(null)
  const [signedDocument, setSignedDocument] = React.useState([])
  const { setResponse } = React.useContext(ResponseContext)
  const [checkOutVisit, setCheckOutVisit] = React.useState({
    reference_id: '',
    location_id: '',
    estimated_time_arrival: null,
    estimated_time_complete: null,
    sender_source: 'DC',
    visitor_id: null,
    is_tac_enabled: false,
    is_sms_enabled: false,
    is_email_enabled: false,
    visitor_info: {
      visitor_id: null,
      title_prefix: '',
      first_name: '',
      last_name: '',
      company_id: '',
      employee_id: '',
      company_name: '',
      email_address: '',
      phone_number: '',
      type: '',
      created_by: '',
      updated_by: '',
      created_time: '',
      modified_time: '',
      visitor_details: {},
    },
    visit_status: 'check-out',
    updated_by: `${systemName}`,
    user_id: `${systemName}`,
    attachments: [],
    activity_comments: '',
    custom_field_details: {
      check_out_details: {},
    },
  })
  const navigate = useNavigate()
  const makeToast = ToastProvider.useToaster()

  const handleChangeMultiSelectCheckIn = (field, obj, value) => {
    if (field === 'check_out_details') {
      let controlName = obj.attribute_name
      if (obj.core_field === true) {
        if ([obj?.attribute_name] in checkOutVisit.visitor_info) {
          setCheckOutVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setCheckOutVisit((prevState) => ({
            ...prevState,
            [controlName]: value,
          }))
        }
      } else {
        setCheckOutVisit((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            check_out_details: {
              ...prevState.custom_field_details.check_out_details,
              [obj.attribute_name]: value,
            },
          },
        }))
      }
    }
  }
  const handleChangeOptionCheckOut = (key, obj, value) => {
    if (key === 'check_out_details') {
      let controlName = obj.attribute_name
      if (obj.core_field === true) {
        if ([obj?.attribute_name] in checkOutVisit.visitor_info) {
          setCheckOutVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setCheckOutVisit((prevState) => ({
            ...prevState,
            [controlName]: value,
          }))
        }
      } else {
        setCheckOutVisit((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            check_out_details: {
              ...prevState.custom_field_details.check_out_details,
              [obj.attribute_name]: value,
            },
          },
        }))
      }
    }
  }

  const validationForCheckOut = () => {
    //Check validation for visitor
    let message = null
    customMetaData?.custom_field_details.check_out_details.map(
      // eslint-disable-next-line
      (mappedObject) => {
        const attributeName = mappedObject.attribute_name

        if (mappedObject?.visible === true && mappedObject.required === true) {
          if (mappedObject.type !== 'Document Sign') {
            if (mappedObject.core_field === true) {
              if (checkOutVisit[attributeName] === '') {
                message = mappedObject.label + ' cannot be blank!'
              }
              if (checkOutVisit.visitor_info[attributeName] === '') {
                message = attributeName + ' cannot be blank!'
              }
            } else if (
              checkOutVisit.custom_field_details.check_out_details[
                attributeName
              ] === '' ||
              checkOutVisit.custom_field_details.check_out_details[
                attributeName
              ] === undefined
            ) {
              message = mappedObject.label + ' cannot be blank!'
            } else if (
              mappedObject.type === 'list & child' &&
              mappedObject?.children_details?.length > 0
            ) {
              mappedObject?.children_details?.map((childdetails) => {
                let domain = domainLookUp?.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  checkOutVisit.custom_field_details?.check_out_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  checkOutVisit.custom_field_details?.check_out_details[
                    mappedObject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedObject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }
  const getCheckOutformDetails = () => {
    return (
      <>
        <RenderFields
          field={'check_out_details'}
          customData={customMetaData?.custom_field_details?.check_out_details}
          domainLookUp={domainLookUp}
          handleChangeOption={handleChangeOptionCheckOut}
          visit={checkOutVisit}
          setAutocompleteValue={setAutocompleteValue}
          autocompleteValue={autocompleteValue}
          setSignedDocument={setSignedDocument}
          signedDocument={signedDocument}
          handleChangeMultiSelect={handleChangeMultiSelectCheckIn}
        />
      </>
    )
  }
  const handleSuccesToast = (errortext) => {
    makeToast({
      type: 'success',
      heading: 'Success',
      message: errortext,
    })
  }
  const handleFailureToast = (errortext) => {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: errortext,
    })
  }

  const handleError = (event) => {
    var ErrorValidationFunction1 = null
    ErrorValidationFunction1 = validationForCheckOut()
    if (
      ErrorValidationFunction1?.length > 0 &&
      ErrorValidationFunction1 !== undefined &&
      ErrorValidationFunction1 !== null
    ) {
      handleFailureToast(ErrorValidationFunction1)
    }

    if (ErrorValidationFunction1 == null) {
      handleSubmit(event)
    }
  }
  const handleSubmit = () => {
    checkOutVisit.system_name = systemName
    checkOutVisit.attachments = signedDocument
    checkOutVisit.user_id = 'Z00BKG2'
    checkOutVisit.visit_status = 'check-out'
    UpdateVisit(visitID, checkOutVisit, visitUUID).then((res) => {
      setResponse({
        routeFlag: 'checkoutcreated',
        flag: '',
        data: res?.data?.created_time,
      })
      handleSuccesToast('')
      navigate('/checkinoutsuccess')
    })
  }
  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="flex-start"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input.Info className="hc-fs-xl">Target Check-Out Form</Input.Info>
            <Input.Info className="hc-fs-md">
              Submit your details now for instant check-in at the Target
              facility{' '}
            </Input.Info>
            <Divider width="2" direction="horizontal" wide={5} />
            <Grid.Container direction="row" justify="space-between">
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Visitor Type: <strong>{visitorTypeDetails?.type}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Location Id: <strong>{locationId}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>

        <Grid.Container
          justify="space-around"
          direction="column"
          spacing="dense"
          //align="center"
          xs={12}
        >
          <Grid.Item>
            <Input
              noValidate
              onSubmit={() => {
                console.log('Input submitted')
              }}
            >
              {customMetaData && getCheckOutformDetails()}
            </Input>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            {customMetaData ? (
              <>
                <Button
                  type="primary"
                  size="expanded"
                  className="hc-pa-expanded"
                  onClick={() => handleError()}
                >
                  Submit Details <EnterpriseIcon icon={ChevronRightIcon} />
                </Button>
              </>
            ) : (
              <></>
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
