/* eslint-disable no-unused-vars */
import React from 'react'
import { Grid, Form, Chip } from '@enterprise-ui/canvas-ui-react'
// import moment from 'moment-timezone'
import ParentandChildSelectCompnent from './ParentandChildSelectComponent'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import '@enterprise-ui/canvas-ui-css-autocomplete'
// import { DocumentSignIndex } from '../../../globalComponents/formComponents/DocumentSign'
import { useEnv } from '@praxis/component-runtime-env'

const RenderFields = ({
  field,
  customData,
  domainLookUp,
  handleChangeOption,
  visit,
  visitCustomText,
  signedDocument,
  setSignedDocument,
  handleChangeMultiSelect,
}) => {
  const env = useEnv()
  const renderOptions = (domainvalue) => {
    let options = []
    // eslint-disable-next-line array-callback-return
    domainLookUp?.filter((option) => {
      if (option?.domain_type === domainvalue) {
        options?.push({
          id: option?.domain_id,
          label: option?.domain_value,
          value: option?.domain_value,
          sort_order: option?.sort_order,
        })
      }
    })
    options.sort((a, b) => a.sort_order - b.sort_order)
    return options
  }
  console.log(visit)
  function findValueByPartialKey(obj, targetKey) {
    let foundValue = undefined

    function searchObject(currentObj) {
      for (const key in currentObj) {
        if (typeof currentObj[key] === 'object') {
          searchObject(currentObj[key])
        } else if (key === targetKey) {
          foundValue = currentObj[key]
          console.log(foundValue)
          return // Exit loop early if key is found
        }
      }
    }
    searchObject(obj)
    return foundValue
  }

  return Object?.keys(
    customData?.sort(function (a, b) {
      return parseInt(a?.sort_order) - parseInt(b?.sort_order)
    }),
    // eslint-disable-next-line array-callback-return
  ).map((entry) => {
    const attributeName = customData[entry].attribute_name
    if (customData[entry]?.visible) {
      const options = renderOptions(customData[entry]?.domain_type)
      switch (customData[entry]?.type) {
        case 'list':
          return (
            <Form.Field
              id={customData[entry]?.label}
              label={customData[entry]?.label}
              required={customData[entry]?.required}
              options={options}
              value={
                customData[entry]?.attribute_name &&
                (env?.workspace_name === 'Store Visits' ||
                  env?.workspace_name === 'DSD Vendor')
                  ? customData[entry]?.attribute_name
                    ? findValueByPartialKey(
                        visit,
                        customData[entry]?.attribute_name,
                      )
                    : ''
                  : customData[entry].core_field
                    ? visit[customData[entry]?.attribute_name]
                    : visitCustomText !== null
                      ? visitCustomText?.attributeName
                      : null
              }
              disabled={
                customData[entry]?.domain_type !== null &&
                (customData[entry]?.domain_type === 'DISTRIBUTOR' ||
                  customData[entry]?.domain_type === 'MANUFACTURE')
                  ? true
                  : false
              }
              type="select"
              onUpdate={(id, value) => {
                handleChangeOption(field, customData[entry], value)
              }}
              classname="hc-pa-3x"
            />
          )
        case 'number':
          return (
            <Form.Field
              classname="hc-pa-3x"
              label={customData[entry]?.label}
              type={'number'}
              required={customData[entry]?.required}
              value={
                customData[entry].core_field
                  ? visit[customData[entry]?.attribute_name]
                  : visitCustomText !== null
                    ? visitCustomText?.attributeName
                    : null
              }
              onChange={(event) => {
                handleChangeOption(field, customData[entry], event.target.value)
              }}
            />
          )
        case 'boolean':
          return <></> // Empty fragment for 'boolean' type
        case 'text':
          return (
            // <Grid.Container justify="flex-start" spacing="none">
            //   <Grid.Item>
            <Form.Field
              label={customData[entry]?.label}
              type={customData[entry]?.type}
              required={customData[entry]?.required}
              value={
                customData[entry].core_field
                  ? visit[customData[entry]?.attribute_name]
                  : visitCustomText !== null
                    ? visitCustomText?.attributeName
                    : null
              }
              classname="hc-pa-3x"
              onChange={(event) => {
                handleChangeOption(field, customData[entry], event.target.value)
              }}
            />
            //   </Grid.Item>
            // </Grid.Container>
          )
        case 'Camera':
          return <></> // Empty fragment for 'Camera' type
        case 'date':
          return (
            // <Grid.Container justify="flex-start" spacing="none">
            //   <Grid.Item>
            <Form.Field
              label={customData[entry]?.label}
              type="datetime-local"
              classname="hc-pa-3x"
              required={customData[entry]?.required}
              value={
                customData[entry].core_field
                  ? visit[customData[entry]?.attribute_name]
                  : visitCustomText !== null
                    ? visitCustomText?.attributeName
                    : null
              }
              onChange={(event) => {
                handleChangeOption(
                  field,
                  customData[entry],
                  event.target.value ? event.target.value : event.target.value,
                )
              }}
            />
            //  </Grid.Item>
            // </Grid.Container>
          )
        case 'list & child':
          return (
            <>
              <ParentandChildSelectCompnent
                customMetadata={customData}
                visit={visit}
                visitCustomData={visitCustomText}
                attributeName={customData[entry]?.attribute_name}
                onChangeHandler={handleChangeOption}
                domainLookUps={domainLookUp}
                entry={entry}
                handleChangeMultiSelect={handleChangeMultiSelect}
                field={field}
              />
            </>
          )
        case 'multiselect':
          return (
            <>
              {/* <Grid.Container justify="flex-start" spacing="none">
                <Grid.Item> */}
              <Autocomplete
                multiselect
                optionsListNoMinWidth={true}
                id={customData[entry]?.label}
                label={customData[entry]?.label}
                required={customData[entry]?.required}
                value={
                  customData[entry]?.attribute_name
                    ? findValueByPartialKey(
                        visit,
                        customData[entry]?.attribute_name,
                      )
                    : ''
                }
                onUpdate={(id, value) => {
                  handleChangeMultiSelect(field, customData[entry], value)
                }}
                options={options}
              />
              {/* </Grid.Item> */}
              {/* </Grid.Container> */}
            </>
          )
        case 'Document Sign':
          return (
            <>
              {/* <DocumentSignIndex
                label={customData[entry]?.label}
                customData={customData[entry]}
                setSignedDocument={setSignedDocument}
                signedDocument={signedDocument}
              /> */}
            </>
          )
        default:
          return <></> // Empty fragment for other types
      }
    }
  })
}
export default RenderFields
