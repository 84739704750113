import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'

import { ErrorContent } from '../../globalComponents/baseComponents/ErrorContent'
import { NewVisitIndex } from '../../views/newvisit'
import { CheckoutIndex } from '../../views/existingvisit/checkout'
import { Home } from '../../views/Home'
import { SuccessScreenNewVisit } from '../../views/additionalscreen/SuccessScreenNewVisit'
import { SuccessScreenExtVisit } from '../../views/additionalscreen/SuccessScreenExtVisit'
import { CheckInHoldIndex } from '../../views/existingvisit/holdstatus/CheckInHoldStatus'
import { FinalReviewPage } from '../../views/finalreview'
import { AttachmentView } from '../../views/attachmentview'
import { AlertDetails } from '../../views/alertdetails'

export const MainRouter = () => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route path="/newvisit/:id" element={<NewVisitIndex />}></Route>
        <Route path="/checkout/:id" element={<CheckoutIndex />}></Route>
        <Route path="/checkin/:id" element={<CheckoutIndex />}></Route>
        <Route path="/checkinhold/:id" element={<CheckInHoldIndex />}></Route>
        <Route path="/dsd_cases/:id" element={<FinalReviewPage />}></Route>
        <Route path="/alert_system/:id" element={<AlertDetails />}></Route>
        <Route path="/case_attachment/:id" element={<AttachmentView />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route
          path="/visitsuccess/:id"
          element={<SuccessScreenNewVisit />}
        ></Route>
        <Route
          path="/checkinoutsuccess/:id"
          element={<SuccessScreenExtVisit />}
        ></Route>
      </Routes>
    </ErrorBoundary>
  )
}
