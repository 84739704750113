/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import moment from 'moment-timezone'
import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'
import RenderFields from '../Utils/RenderFields'
import { useEnv } from '@praxis/component-runtime-env'

import {
  Button,
  Divider,
  Grid,
  Input,
  Layout,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { CreateVisitService } from '../../service/VisitExternalApiCalls'
import EnterpriseIcon, { ChevronRightIcon } from '@enterprise-ui/icons'
import { ResponseContext } from '../../contexts/ResponseContext'
export const NewVisitIndex = () => {
  const {
    systemName,
    visitorTypeId,
    customMetaData,
    visitorTypeDetails,
    locationId,
    visitUUID,
    domainLookUp,
  } = React.useContext(VisitUUIDContext)
  const env = useEnv()
  const { setResponse } = React.useContext(ResponseContext)
  const makeToast = ToastProvider.useToaster()
  const [signedDocument, setSignedDocument] = React.useState([])
  const [autocompleteValue, setAutocompleteValue] = React.useState(null)
  let initalState = {
    system_name: '',
    reference_id: '',
    visit_status: '',
    location_id: '',
    visit_purpose: '',
    estimated_time_arrival: moment().utc().format(),
    estimated_time_complete: null,
    sender_source: systemName,
    visitor_id: null,
    is_tac_enabled: false,
    is_sms_enabled: false,
    is_email_enabled: false,
    visit_comments: '',
    visitor_info: {
      visitor_id: null,
      title_prefix: '',
      first_name: '',
      last_name: '',
      company_id: '',
      employee_id: '',
      company_name: '',
      email_address: '',
      phone_number: '',
      type: '',
      created_by: '',
      updated_by: '',
      created_time: '',
      modified_time: '',
      visitor_details: {},
    },

    created_time: '',
    modified_time: '',
    custom_field_details: {
      visit_details: {},
      assigned_details: {},
      en_route_details: {},
      check_in_details: {},
      check_out_details: {},
    },
    attachments: [],
    visitor_type_id: '',
  }
  const [finalAcceptedStatus, setFinalAcceptedStatus] = React.useState([])
  const [visit, setVisit] = React.useState(initalState)
  React.useEffect(() => {
    let firstThreeStatuses =
      visitorTypeDetails.custom_field_details.visit_statuses.slice(0, 3)
    setFinalAcceptedStatus(firstThreeStatuses)
  }, [visitorTypeDetails])

  const handleChangeMultiSelectVisit = (field, obj, value) => {
    console.log(value)

    if (field === 'visit_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in visit.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        console.log(value)

        setVisit((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState?.custom_field_details,
            visit_details: {
              ...prevState?.custom_field_details?.visit_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }

  const handleChangeMultiSelectVisitor = (field, obj, value) => {
    console.log(value)
    if (field === 'visitor_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in initalState.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState?.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState.visitor_info,
            visitor_details: {
              ...prevState.visitor_info.visitor_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }

  const handleChangeMultiSelectAssign = (field, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        console.log(value)

        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          assigned_details: {
            ...prevState?.custom_field_details?.assigned_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const handleChangeVisit = (key, obj, value) => {
    if (key === 'visit_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in visit.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        setVisit((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState?.custom_field_details,
            visit_details: {
              ...prevState?.custom_field_details?.visit_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }
  const handleChangeVisitor = (key, obj, value) => {
    if (key === 'visitor_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in initalState.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState?.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState.visitor_info,
            visitor_details: {
              ...prevState.visitor_info.visitor_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }

  const handleChangeAssign = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          assigned_details: {
            ...prevState?.custom_field_details?.assigned_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const handleChangeEnRoute = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          en_route_details: {
            ...prevState?.custom_field_details?.en_route_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }
  const handleChangeMultiSelectEnRoute = (field, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          en_route_details: {
            ...prevState?.custom_field_details?.en_route_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }
  const handleChangeCheckInn = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState.custom_field_details,
          check_in_details: {
            ...prevState?.custom_field_details?.check_in_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const validationForVisit = () => {
    let message = null
    customMetaData?.custom_field_details?.visit_details.map((mappedobject) => {
      if (mappedobject.visible === true && mappedobject.required === true) {
        if (mappedobject.type !== 'Document Sign') {
          if (mappedobject.core_field === true) {
            if (visit[mappedobject.attribute_name] === '') {
              message = mappedobject.attribute_name + ' cannot be blank!'
            }
            if (visit.visitor_info[mappedobject.attribute_name] === '') {
              message = mappedobject.attribute_name + ' cannot be blank!'
            }
          } else if (
            visit.custom_field_details?.visit_details[
              mappedobject.attribute_name
            ] === '' ||
            visit.custom_field_details?.visit_details[
              mappedobject.attribute_name
            ] === undefined
          ) {
            message = mappedobject.attribute_name + ' cannot be blank!'
          } else if (
            mappedobject.type === 'list & child' &&
            mappedobject?.children_details?.length > 0
          ) {
            mappedobject?.children_details?.map((childdetails) => {
              let domain = domainLookUp.find(
                (domain) => domain.domain_id === childdetails.parent_id,
              )
              if (
                childdetails.child_type === 'Document Sign' &&
                signedDocument?.length === 0 &&
                childdetails?.required === true
              ) {
                message = childdetails.child_label + ' cannot be blank!'
              }
              if (
                visit.custom_field_details?.visit_details[
                  childdetails.attribute_name
                ] === undefined &&
                childdetails?.required === true &&
                visit.custom_field_details?.visit_details[
                  mappedobject?.attribute_name
                ] === domain?.domain_value &&
                childdetails?.child_type !== 'Document Sign'
              ) {
                message = childdetails.child_label + ' cannot be blank!'
              }
            })
          }
        } else if (signedDocument?.length === 0) {
          message = mappedobject.label + ' cannot be blank!'
        }
      }
    })
    return message
  }

  const validationForVisitor = () => {
    let message = null
    customMetaData?.custom_field_details?.visitor_details?.map(
      (mappedobject) => {
        if (mappedobject.visible === true && mappedobject.required === true) {
          if (mappedobject.type !== 'Document Sign') {
            if (mappedobject.core_field === true) {
              if (visit.visitor_info[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
              if (visit[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
            } else if (
              visit.visitor_info?.visitor_details[
                mappedobject.attribute_name
              ] === '' ||
              visit.visitor_info?.visitor_details[
                mappedobject.attribute_name
              ] === undefined
            ) {
              message = mappedobject.attribute_name + ' cannot be blank!'
            } else if (
              mappedobject.type === 'list & child' &&
              mappedobject?.children_details?.length > 0
            ) {
              mappedobject?.children_details?.map((childdetails) => {
                console.log(
                  childdetails,
                  visit.custom_field_details?.visitor_details,
                )
                let domain = domainLookUp.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  visit.custom_field_details?.visitor_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  visit.custom_field_details?.visitor_details[
                    mappedobject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedobject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }

  const validationForAssign = () => {
    let message = null
    customMetaData?.custom_field_details?.assigned_details?.map(
      (mappedobject) => {
        if (mappedobject.visible === true && mappedobject.required === true) {
          if (mappedobject.type !== 'Document Sign') {
            if (mappedobject.core_field === true) {
              if (visit[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
              if (visit.visitor_info[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
            } else if (
              visit.custom_field_details?.assigned_details[
                mappedobject.attribute_name
              ] === '' ||
              visit.custom_field_details?.assigned_details[
                mappedobject.attribute_name
              ] === undefined
            ) {
              message = mappedobject.attribute_name + ' cannot be blank!'
            } else if (
              mappedobject.type === 'list & child' &&
              mappedobject?.children_details?.length > 0
            ) {
              mappedobject?.children_details?.map((childdetails) => {
                let domain = domainLookUp?.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                console.log(childdetails, signedDocument)
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  visit.custom_field_details?.assigned_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  visit.custom_field_details?.assigned_details[
                    mappedobject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedobject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }

  const validationForEnroute = () => {
    let message = null
    customMetaData?.custom_field_details?.en_route_details?.map(
      (mappedobject) => {
        if (mappedobject.visible === true && mappedobject.required === true) {
          if (mappedobject.type !== 'Document Sign') {
            if (mappedobject.core_field === true) {
              if (visit[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
              if (visit.visitor_info[mappedobject.attribute_name] === '') {
                message = mappedobject.attribute_name + ' cannot be blank!'
              }
            } else if (
              visit.custom_field_details?.en_route_details[
                mappedobject.attribute_name
              ] === '' ||
              visit.custom_field_details?.en_route_details[
                mappedobject.attribute_name
              ] === undefined
            ) {
              message = mappedobject.attribute_name + ' cannot be blank!'
            } else if (
              mappedobject.type === 'list & child' &&
              mappedobject?.children_details?.length > 0
            ) {
              mappedobject?.children_details?.map((childdetails) => {
                let domain = domainLookUp?.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                console.log(childdetails, signedDocument)
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  visit.custom_field_details?.en_route_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  visit.custom_field_details?.en_route_details[
                    mappedobject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedobject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }

  const validationForCheckIn = () => {
    let message = null
    customMetaData.custom_field_details?.check_in_details.map(
      // eslint-disable-next-line
      (mappedObject) => {
        if (mappedObject?.visible === true && mappedObject.required === true) {
          if (mappedObject.type !== 'Document Sign') {
            if (mappedObject?.core_field === true) {
              if (visit.visitor_info[mappedObject.attribute_name] === '') {
                message = mappedObject.attribute_name + ' cannot be blank!'
              }
              if (visit[mappedObject.attribute_name] === '') {
                message = mappedObject.label + ' cannot be blank!'
              }
            } else if (
              visit?.custom_field_details?.check_in_details[
                mappedObject?.attribute_name
              ] === '' ||
              visit?.custom_field_details?.check_in_details[
                mappedObject?.attribute_name
              ] === undefined
            ) {
              message = mappedObject.label + ' cannot be blank!'
            } else if (
              mappedObject.type === 'list & child' &&
              mappedObject?.children_details?.length > 0
            ) {
              mappedObject?.children_details?.map((childdetails) => {
                let domain = domainLookUp?.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                console.log(childdetails, signedDocument)
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  visit.custom_field_details?.check_in_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  visit.custom_field_details?.check_in_details[
                    mappedObject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedObject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }

  const validationForCheckOut = () => {
    //Check validation for visitor
    let message = null
    customMetaData?.custom_field_details.check_out_details.map(
      // eslint-disable-next-line
      (mappedObject) => {
        const attributeName = mappedObject.attribute_name

        if (mappedObject.visible === true && mappedObject.required === true) {
          if (mappedObject.type !== 'Document Sign') {
            if (mappedObject.core_field === true) {
              if (visit[attributeName] === '') {
                message = mappedObject.label + ' cannot be blank!'
              }
              if (visit.visitor_info[attributeName] === '') {
                message = attributeName + ' cannot be blank!'
              }
            } else if (
              visit.custom_field_details.check_out_details[attributeName] ===
                '' ||
              visit.custom_field_details.check_out_details[attributeName] ===
                undefined
            ) {
              message = mappedObject.label + ' cannot be blank!'
            } else if (
              mappedObject.type === 'list & child' &&
              mappedObject?.children_details?.length > 0
            ) {
              mappedObject?.children_details?.map((childdetails) => {
                let domain = domainLookUp?.find(
                  (domain) => domain.domain_id === childdetails.parent_id,
                )
                console.log(childdetails, signedDocument)
                if (
                  childdetails.child_type === 'Document Sign' &&
                  signedDocument?.length === 0 &&
                  childdetails?.required === true
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
                if (
                  visit.custom_field_details?.check_out_details[
                    childdetails.attribute_name
                  ] === undefined &&
                  childdetails?.required === true &&
                  visit.custom_field_details?.check_out_details[
                    mappedObject?.attribute_name
                  ] === domain?.domain_value &&
                  childdetails?.child_type !== 'Document Sign'
                ) {
                  message = childdetails.child_label + ' cannot be blank!'
                }
              })
            }
          } else if (signedDocument?.length === 0) {
            message = mappedObject.label + ' cannot be blank!'
          }
        }
      },
    )
    return message
  }

  const handleChangeMultiSelectCheckIn = (field, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState.custom_field_details,
          check_in_details: {
            ...prevState?.custom_field_details?.check_in_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  function MapValidatorFunction(statusName) {
    let ErrorMessage = null
    switch (statusName) {
      case 'VisitField':
        ErrorMessage = validationForVisit()
        return ErrorMessage
      case 'VisitorField':
        ErrorMessage = validationForVisitor()
        return ErrorMessage
      case 'Assigned':
        ErrorMessage = validationForAssign()
        return ErrorMessage
      case 'En-Route':
        ErrorMessage = validationForEnroute()
        return ErrorMessage
      case 'Check-in':
        ErrorMessage = validationForCheckIn()
        return ErrorMessage
      case 'Check-out':
        ErrorMessage = validationForCheckOut()
        return ErrorMessage
      default:
        return 'Error'
    }
  }

  function statusNameHandlers(status) {
    switch (status) {
      case 'VisitField':
        return 'VisitField'
      case 'VisitorField':
        return 'VisitorField'
      case 'Assign':
        return 'Assigned'
      case 'En-Route':
        return 'En Route'
      case 'Check-in':
        return 'Check-in'
      case 'Check-out':
        return 'Check-out'
      default:
        return 'Check-in'
    }
  }

  const handleSuccesToast = (errortext) => {
    makeToast({
      type: 'success',
      heading: 'Success',
      message: errortext,
    })
  }
  const handleFailureToast = (errortext) => {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: errortext,
    })
  }

  const handleSingleViewSubmit = (event) => {
    const errorValidationFunctions = []

    finalAcceptedStatus.forEach((status, index) => {
      const statusHandler = statusNameHandlers(status)
      window[`ErrorValidationFunction${index + 1}`] =
        MapValidatorFunction(statusHandler)

      const currentErrorValidationFunction =
        window[`ErrorValidationFunction${index + 1}`]

      if (
        currentErrorValidationFunction?.length > 0 &&
        currentErrorValidationFunction !== undefined &&
        currentErrorValidationFunction !== null
      ) {
        handleFailureToast(currentErrorValidationFunction)
      }

      errorValidationFunctions.push(currentErrorValidationFunction)
    })
    console.log('inside', errorValidationFunctions)
    if (errorValidationFunctions.every((func) => func === null)) {
      // All error validation functions are null
      // setCircleProgress(true);
      handleSubmit(event)
    }
  }
  const handleSubmit = (event) => {
    const statusFiltered = env?.walkin
      ? statusNameHandlers(finalAcceptedStatus[2])
      : statusNameHandlers(finalAcceptedStatus[finalAcceptedStatus.length - 1])

    visit.system_name = systemName
    visit.location_id = locationId
    visit.user_id = `${systemName}-kiosk`
    visit.attachments = signedDocument
    visit.visit_status = statusFiltered
    visit.estimated_time_arrival = visit.estimated_time_arrival
      ? moment
          .utc(visit.estimated_time_arrival, 'YYYYMMDD HH:mm:ss')
          .toISOString()
      : moment(moment().utcOffset(0).toISOString())
    visit.visitor_type_id = visitorTypeId
    CreateVisitService(visit, visitUUID).then((res) => {
      setResponse({
        routeFlag: 'created',
        flag: '',
        data: res?.data?.created_time,
      })
      handleSuccesToast('')
    })
  }

  const getListStepView = React.useMemo(() => {
    return (stepName) => {
      switch (stepName) {
        case 'VisitField':
          return (
            <>
              {customMetaData?.custom_field_details?.visit_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'visit_details'}
                    customData={
                      customMetaData?.custom_field_details?.visit_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeVisit}
                    visit={visit}
                    visitCustomText={visit?.custom_field_details?.visit_details}
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                    handleChangeMultiSelect={handleChangeMultiSelectVisit}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'VisitorField':
          return (
            <>
              {customMetaData?.custom_field_details?.visitor_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'visitor_details'}
                    customData={
                      customMetaData?.custom_field_details?.visitor_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeVisitor}
                    visit={visit}
                    visitCustomText={visit?.visitor_info}
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                    handleChangeMultiSelect={handleChangeMultiSelectVisitor}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Assign':
          return (
            <>
              {customMetaData?.custom_field_details?.assigned_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'assigned_details'}
                    customData={
                      customMetaData?.custom_field_details?.assigned_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeAssign}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.assigned_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                    handleChangeMultiSelect={handleChangeMultiSelectAssign}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'En-Route':
          return (
            <>
              {customMetaData?.custom_field_details?.en_route_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'en_route_details'}
                    customData={
                      customMetaData?.custom_field_details?.en_route_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeEnRoute}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.en_route_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                    handleChangeMultiSelect={handleChangeMultiSelectEnRoute}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Check-in':
          return (
            <>
              {customMetaData?.custom_field_details?.check_in_details.length >
              0 ? (
                <>
                  <RenderFields
                    field={'check_in_details'}
                    customData={
                      customMetaData?.custom_field_details?.check_in_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeCheckInn}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.check_in_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                    handleChangeMultiSelect={handleChangeMultiSelectCheckIn}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Check-Out':
          return <></>
        default:
          return 'Unknown step'
      }
    }
  }, [
    customMetaData?.custom_field_details?.visit_details,
    customMetaData?.custom_field_details?.visitor_details,
    customMetaData?.custom_field_details?.assigned_details,
    customMetaData?.custom_field_details?.en_route_details,
    customMetaData?.custom_field_details?.check_in_details,
    visit,
    domainLookUp,
    handleChangeVisitor,
    handleChangeAssign,
    handleChangeCheckInn,
  ])

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="flex-start"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input.Info className="hc-fs-xl">Target Check-in Form</Input.Info>
            <Input.Info className="hc-fs-md">
              Submit your details now for instant check-in at the Target
              facility{' '}
            </Input.Info>
            <Divider width="2" direction="horizontal" wide={5} />
            <Grid.Container direction="row" justify="space-between">
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Visitor Type: <strong>{visitorTypeDetails?.type}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Location Id: <strong>{locationId}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>

        <Grid.Container
          justify="center"
          direction="column"
          spacing="dense"
          //align="center"
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input
              xs={12}
              noValidate
              onSubmit={() => {
                console.log('Input submitted')
              }}
            >
              {finalAcceptedStatus?.map((mapingObjects) => {
                return <>{getListStepView(mapingObjects)}</>
              })}
            </Input>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            {customMetaData ? (
              <>
                <Button
                  type="primary"
                  size="expanded"
                  className="hc-pa-expanded"
                  onClick={() => handleSingleViewSubmit()}
                >
                  Submit Details <EnterpriseIcon icon={ChevronRightIcon} />
                </Button>
              </>
            ) : (
              <></>
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
